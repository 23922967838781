export default [
    // {
    //   title: 'Dashboards',
    //   icon: 'HomeIcon',
    //   tag: '2',
    //   tagVariant: 'light-warning',
    //   children: [
    //     {
    //       title: 'eCommerce',
    //       route: 'dashboard-ecommerce',
    //     },
    //     {
    //       title: 'Analytics',
    //       route: 'dashboard-analytics',
    //     },
    //   ],
    // },
    {
        title: 'Data Pasar',
        route: 'data-pasar',
        icon: 'DollarSignIcon',
    },
    {
        title: 'Hak Akses',
        route: 'hak-akses',
        icon: 'LockIcon',
    },
    {
        title: 'User',
        route: 'pengguna',
        icon: 'UsersIcon',
    },
    {
        title: 'Pemantauan Stok',
        route: 'pemantauan-sembako',
        icon: 'FileTextIcon',
    },
    {
        title: 'Verifikasi Pemantauan',
        route: 'verif-pemantauan-sembako',
        icon: 'CheckSquareIcon',
    },
    {
        title: 'Pemantauan Sembako',
        icon: 'PieChartIcon',
        // tag: '3',
        tagVariant: 'light-danger',
        children: [
            {
                title: 'Ketersediaan',
                route: 'grafik-ketersediaan',
            },
            {
                title: 'Harga',
                route: 'grafik-harga',
            },
            {
                title: 'Ketersediaan dan Harga',
                route: 'grafik-ketersediaandanharga',
            },
        ],
    },
  ]
  