export default [
    {
        title: 'Pemantauan Sembako',
        icon: 'PieChartIcon',
        // tag: '3',
        tagVariant: 'light-danger',
        children: [
            {
                title: 'Ketersediaan',
                route: 'grafik-ketersediaan',
            },
            {
                title: 'Harga',
                route: 'grafik-harga',
            },
        ],
    },
  ]
  